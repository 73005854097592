import React, { useEffect } from "react"
import styled from "styled-components"
import Posts from "../components/blogList/Posts"
import { Dot, GradientText, SectionHeader } from "../components/Common"
import Head from "../components/head"
import Projects from "../components/projectListing/Projects"
import { BREAKPOINTS } from "../constants"
import Layout from "../layouts/layout"
import HeroImage from "../../static/website_assets/hero_img.png"
import HeroImageWebp from "../../static/website_assets/hero_img.webp"
import HeroBg from "../../static/website_assets/hero_bg.svg"
import SparkleIcon from "../../static/website_assets/Sparkle.svg"
import { motion } from "framer-motion"
// import pattern from "../../static/website_assets/bottom-pattern.svg"

const Intro = styled.p`
  font-size: 1.5rem;
  color: var(--color-theme-accent);
  margin: 0;
  margin-bottom: 0.8rem;
  font-weight: 500;
`
const Tagline = styled.h1`
  margin: 0;
  font-size: var(--hero);
  line-height: var(--hero);
  margin-top: 0 !important;
`

const Hero = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20vh;
  padding-bottom: 20vh;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    padding-top: 4vh;
    padding-bottom: 8vh;
    > :first-child {
      margin-top: 2rem;
    }
    flex-direction: column-reverse;
  }
  /* ::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100vh;
    mask: url({pattern});
    background-color: var(--color-theme-accent);
    opacity: 0.2;
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: repeat-x;
    z-index: 0;
  } */
`

const HeroContent = styled.div`
  /* @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    margin-left: 1rem;
    margin-right: 1rem;
  } */
`

const HeroStatus = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  color: var(--color-theme-text);
  font-size: var(--tags);
  position: relative;
  margin-top: 1.6rem;
  width: fit-content;
  max-width: 80vw;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 33;
  padding: 0.32rem 0.64rem;
  padding-left: 0;
  background-color: var(--color-background-48-dp-elevation);
  box-shadow: inset -6px 0 1rem var(--color-background-48-dp-elevation);
  > div {
    @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
      box-shadow: 6px 0 1rem var(--color-background-48-dp-elevation);
    }
    background-color: var(--color-background-48-dp-elevation);
    z-index: 20;
    width: 28px;
    padding-left: 0.64rem;
    height: auto;
    ::before {
      opacity: 1;
      position: relative;
      left: 0;
      top: 0;
      content: ".";
      display: inline-block;
      height: calc(var(--tags)+"4px");
      width: 28px;
      background-color: var(--color-theme-accent);
      mask: url(${SparkleIcon}) no-repeat left;
      z-index: 20;
      /* filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 100%)); */
    }
  }
  > p {
    white-space: nowrap;
    padding-left: 8px;
    > span {
      display: inline-block;
      width: max-content;
      /* show the marquee just outside the paragraph */
      will-change: transform;
      animation: marquee 8s infinite alternate linear;
    }
  }

  @keyframes marquee {
    0% {
      transform: translate(12%, 0);
    }
    100% {
      transform: translate(-48%, 0);
    }
  }
`

const HeroArtBorder = styled(motion.div)`
  flex-shrink: 0;
  background: var(--color-theme-auxiliary);
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: relative;
  margin: 0.2rem;
  overflow: hidden;
  will-change: transform;
  height: 20rem;
  width: 20rem;
  aspect-ratio: 1/1;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    align-self: flex-start;
    max-width: 420px;
    border-radius: 1.5rem;
    width: fit-content;
    height: auto;
    aspect-ratio: 1/1;
  }
  > * {
    margin: 0.2rem;
  }
  ::before {
    display: inline-block;
    content: "";
    position: absolute;
    left: 0;
    background-color: var(--color-theme-accent);
    background-blend-mode: difference;
    mask: url(${HeroBg}) repeat;
    opacity: 0.2;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    animation: moving_background 12s infinite alternate linear;
    @keyframes moving_background {
      to {
        transform: translateX(-50%);
      }
    }
  }
  > picture {
    width: 100%;
    height: 100%;
  }
`
const HeroArt = styled.img`
  z-index: 4;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  transform: translate(0, 0);
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    width: 72vw;
    aspect-ratio: 1/1;
    height: auto;
    flex-grow: initial;
    border-radius: 1.5rem;
  }
`
const HeroPara = styled.p`
  margin-top: 1.6rem;
  /* padding: 1.6rem 0; */
  max-width: 54ch;
  font-size: calc(var(--paragraph) * 0.9);
`
const HeroLink = styled.a`
  text-decoration: none;
  background-image: var(--color-theme-gradient-1);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  cursor: pointer;
  :hover {
    animation: hue 3s infinite linear;
  }
`

function isElementOverflowing(element) {
  const overflowX = element.offsetWidth > element.parentElement.offsetWidth
  return overflowX
}

function wrapContentsInMarquee(element) {
  const marquee = document.createElement("span"),
    contents = element.innerText
  marquee.innerText = contents
  element.innerHTML = ""
  element.appendChild(marquee)
}

export default function IndexPage(props) {
  useEffect(() => {
    const HeroStat = document.getElementById("HeroStatus")
    if (isElementOverflowing(HeroStat)) {
      // console.log("Yes")
      wrapContentsInMarquee(HeroStat)
    } else {
      // console.log(HeroStat.offsetWidth, HeroStat.scrollWidth)
    }
  })

  return (
    // <Layout showpattern={true}>
    <Layout>
      <Head title="Home" />
      <Hero>
        <HeroContent>
          <Intro>
            Hi, I'm Sourabh<Dot>.</Dot>
          </Intro>
          <Tagline>
            I build <GradientText>experiences</GradientText> worth your while
            <Dot>.</Dot>
          </Tagline>
          <HeroStatus>
            <div>
              <span></span>
            </div>
            <p id="HeroStatus">
              UX Designer{" "}
              <HeroLink
                target="_blank"
                href="https://www.nutanix.com/"
                rel="noreferrer"
              >
                @Nutanix
              </HeroLink>
            </p>
          </HeroStatus>
          <HeroPara>
            Currently pursuing masters in Human-Computer Interaction{" "}
            <HeroLink
              target="_blank"
              href="https://ischool.umd.edu/academics/master-of-science-in-human-computer-interaction"
              rel="noreferrer"
            >
              @UMD
            </HeroLink>
            , previously scaled{" "}
            <HeroLink
              target="_blank"
              href="https://filterpixel.com/"
              rel="noreferrer"
            >
              @FilterPixel
            </HeroLink>{" "}
            as a Product Designer and built WebApps{" "}
            <HeroLink
              target="_blank"
              href="https://informatica.com/"
              rel="noreferrer"
            >
              @Informatica
            </HeroLink>{" "}
            as a UI Engineer<Dot>.</Dot>
          </HeroPara>
        </HeroContent>
        <HeroArtBorder
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
          }}
          whileHover={{ scale: 1.1 }}
        >
          <picture>
            <source type="image/webp" srcSet={HeroImageWebp} />
            <HeroArt src={HeroImage} alt="Sourabh Daroji's Portrait" />
          </picture>
        </HeroArtBorder>
      </Hero>
      <SectionHeader>Featured Projects</SectionHeader>
      <Projects />
      <SectionHeader>Recent Posts</SectionHeader>
      <Posts limit={3} />
    </Layout>
  )
}
